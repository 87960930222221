import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"
import ReactAudioPlayer from 'react-audio-player'

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import { AmazonPodcastSvg, ApplePodcastSvg, GooglePodcastSvg, SpotifySvg } from "../utils/svgs"
import "./../assets/css/@wordpress/block-library/build-style/style.css"
import "./../assets/css/@wordpress/block-library/build-style/theme.css"
import * as s from "./../assets/css/growth-podcast.module.css"
import Layout from "./../components/layout"

import PodcastsHost from "../components/podcasts/PodcastsHost/PodcastsHost"
import PodcastsJoin from "../components/podcasts/PodcastsJoin/PodcastsJoin"
import schemaBuilder from "../utils/schemaBuilder"
import Seo from "./../components/seo"
import YoutubeVideo from './../components/youtubeVideo/youtubeVideo'

const PodcastTemplate = ({ data: { post, author, site }, location }) => {

  const [showIframe, setShowIframe] = React.useState(false);

  const authorAvatarImage = {
    data: author.users.avatar?.localFile?.childImageSharp?.gatsbyImageData,
    alt: author.users.avatar?.altText || ``,
  };

  const schema = schemaBuilder({ growthPodcast: post, author: author }, 'growth-podcast', site);

  const imagePlaceholder = {
    data: post?.growthPodcasts?.placeholderImage?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post?.growthPodcasts?.placeholderImage?.altText || ``,
  };

  let placeholderImageUrl = "";
  if (!!post?.growthPodcasts?.youtubeVideoId) {
    if (!!imagePlaceholder.data) {
      placeholderImageUrl = imagePlaceholder
    } else {
      placeholderImageUrl = `https://img.youtube.com/vi/${post?.growthPodcasts?.youtubeVideoId}/maxresdefault.jpg`;
    }
  }

  return (
    <Layout  pageTitle={`Podcast - ${post.title}`}>
      <Seo
        title={post.title}
        description={post.excerpt}
        pageSeo={post}
        location={location}
        schema={schema}
      />
      <article className={`${s.podcastTemplate} blog-post`}>
        <section className={s.singlePodcastContent}>
          <div className="container">
            <Link to="/a-growth-ventures-podcast/" className={s.backBtn}>&lt; back to all episodes</Link>
            <div className={s.singlePodcastContentWrapper}>
              <div className="row">
                <div className="col-lg-6">
                  {/* <iframe src="https://www.youtube.com/embed/2uHaXcHE40A"
                    title="Mastering Growth Hacking: Scaling Strategies, Learning from Failure, and Data-Driven Experimentation"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe> */}
                  {!!post?.growthPodcasts?.youtubeVideoId &&
                    <YoutubeVideo imageUrl={placeholderImageUrl} videoId={post?.growthPodcasts?.youtubeVideoId} wrapperClasses="h-100" sectionClasses="h-100" placeholderStyles={{
                      objectFit: 'cover',
                      maxHeight: '410px',
                    }} />
                  }
                  {(!post?.growthPodcasts?.youtubeVideoId && !!imagePlaceholder.data) &&
                    <GatsbyImage
                      loading="eager"
                      placeholder="dominantColor"
                      image={imagePlaceholder.data}
                      alt={imagePlaceholder.alt}
                      className={`mw-100`} />
                  }

                </div>
                <div className={`col-lg-6 d-flex flex-column justify-content-between ${s.podcastTitleWrapper}`}>
                  <div>
                    <h1 className={s.title}>{!!post?.growthPodcasts?.guest && <b>{post?.growthPodcasts?.guest}: </b>} {post.title}</h1>
                    <div className="mt-3">
                      {!!post?.growthPodcasts?.audioFile &&
                        <ReactAudioPlayer
                          src={post?.growthPodcasts?.audioFile}
                          controls
                          volume={0.5}
                          className='w-100'
                          preload='none'
                          controlsList='nodownload'
                        />
                      }
                    </div>
                  </div>
                  <div className={`d-flex ${s.singlePodcastActionsWrapper}`}>
                    <div className={s.listenOn}>
                      <p>listen on:</p>
                      <div className={`${s.listenOnWrapper} d-flex align-items-center`}>
                        {!!post?.growthPodcasts?.llistenLinks?.spotify &&
                          <a href={post?.growthPodcasts?.llistenLinks?.spotify} target="_blank" className={s.podcastListenOnLink}>
                            <SpotifySvg />
                          </a>
                        }
                        {!!post?.growthPodcasts?.llistenLinks?.googlePodcast &&
                          <a href={post?.growthPodcasts?.llistenLinks?.googlePodcast} target="_blank" className={s.podcastListenOnLink}>
                            <GooglePodcastSvg />
                          </a>
                        }
                        {!!post?.growthPodcasts?.llistenLinks?.applePodcast &&
                          <a href={post?.growthPodcasts?.llistenLinks?.applePodcast} target="_blank" className={s.podcastListenOnLink}>
                            <ApplePodcastSvg />
                          </a>
                        }
                        {!!post?.growthPodcasts?.llistenLinks?.amazonPodcast &&
                          <a href={post?.growthPodcasts?.llistenLinks?.amazonPodcast} target="_blank" className={s.podcastListenOnLink}>
                            <AmazonPodcastSvg />
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`row ${s.textContent}`}>
                {!!post.content && parse(post.content)}
              </div>
            </div>
          </div>
        </section>
        <PodcastsHost block={{ useLocal: false }} />
        <PodcastsJoin block={{ useLocal: false }} />
      </article>
    </Layout >
  )
}

export default PodcastTemplate

export const podcastQuery = graphql`
  query PodcastById(
    $id: String!
    $authorId: String!
  ) {
    site {
      siteMetadata {
        siteUrl: url
        title
      }
    }
    post: wpGrowthPodcast(id: { eq: $id }) {
      id
      excerpt
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      uploadDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      growthPodcasts {
        guest
        episodNo
        youtubeVideoId
        placeholderImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        llistenLinks {
          applePodcast
          googlePodcast
          spotify: spitify
          amazonPodcast
        }
        audioFile
      }
      seo {
        metaKeywords
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        focuskw
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        twitterImage {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
            extension
          }
        }
        opengraphType
      }
    }
    author: wpUser(id: {eq: $authorId}) {
      avatar {
        size
        url
      }
      users {
        avatar {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      name
      description
      email
      slug
      uri
      seo {
        metaDesc
        social {
          facebook
          linkedIn
          twitter
          wikipedia
        }
      }
    }
  }
`
